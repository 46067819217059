import React from "react"
import Masthead from '../components/Masthead'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlockSimple from '../components/BlockSimple'
import "../components/ImagePuzzleA/styles.css"


const AboutPage = () => (
  <Layout>
    <div className="layout__margin-bottom">
      <SEO title="Costa Rica Best Choice" />
      <div className="masthead__wrapper">
        <div className="masthead">
          <img className="masthead__img" src="https://res.cloudinary.com/dqzg3xd5c/image/upload/c_fill,e_auto_brightness,h_540,q_100,w_1440//v1575615825/canopy.jpg" alt="Guanacaste coast"/>
        </div>
      </div>
      <div className="block-simple__bg">
        <div className="block-simple__wrapper">
          <div className="block-simple__title">We're a company that provides a safe and reliable transportation and tourism service throughout the Guanacaste region.</div>
          <div className="block-simple__text">Costa Rica Best Choice offers a high end and trusty trnsportation service. Using new and comfortable vehicles we guarantee timely and effective pick up and drop offs throughout the whole Guanacaste peninsula. We also prepare and full day or mid day tours for couples, singles and families. At trusted and magical locations we bring our guests to experience the lively and wild jungles of the country. </div>
          <div className="block-simple__text">With more than 10 years in the business we have been able to discover some of the most unique environments and truly inmersive activities for everyone to enjoy the Costa Rican culture. We can transport groups or families of all sizes and can comfort any special needs or request. We adapt the tours to key discoveries you wish to experience. For people of all ages and capacities, we develop custom and unique mid day or full day tours. </div>
        </div>
      </div>
      <div className="imagePuzzleA__wrapper">
        <div className="imagePuzzleA">
          <div className="imagePuzzleA__1 imagePuzzleA__img"></div>
          <div className="imagePuzzleA__2 imagePuzzleA__img"></div>
          <div className="imagePuzzleA__3 imagePuzzleA__img"></div>
        </div>
      </div>
    </div>


  </Layout>
)

export default AboutPage
